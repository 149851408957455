import React, { useEffect, useRef } from 'react'
import {
  ManagerRecommendationInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { get, set } from 'lodash'
import { Avatar, FilterButton, Flex, Text, VStack, Widget } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import { RecommendationType } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import BottomText from '@components/Inputs/partials/BottomText'

interface Props {
  isViewMode: boolean
  type?: RecommendationType
  data?: ManagerRecommendationInterface
  field: string
}

export const BarRaiser = connect(({ isViewMode, data, type, field }: Props) => {
  const { values, errors, submitFailed } = useLapeContext<ReviewScorecardInterface>()
  const ref = useRef<HTMLDivElement>(null)
  const { options } = useFetchOptions<OptionInterface>(selectorKeys.yes_no_range_options)

  useEffect(() => {
    const hasError = get(errors, field)?.keeper_test_section?.questions
    if (
      hasError &&
      !errors.review_data?.deliverables?.cards &&
      !errors.review_data?.functional_skills?.cards &&
      !errors.review_data?.manager_skills?.cards
    ) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [errors.review_data, field])

  if (!data) {
    return null
  }

  if (!data?.keeper_test_section) {
    return null
  }

  const onKeeperChange = (value: string, num: number) => {
    let key

    switch (type) {
      case 'lm':
        key = `review_data.line_manager_extra_section.keeper_test_section.questions.${num}.value`
        break

      case 'fm':
        key = `review_data.functional_manager_extra_section.keeper_test_section.questions.${num}.value`
        break

      case 'peer':
        key = `review_data.peer_extra_section.keeper_test_section.questions.${num}.value`
        break
    }

    if (key) {
      set(values, key, value)
    }
  }

  const renderError = (num: number) => {
    if (!submitFailed) {
      return null
    }

    let key

    switch (type) {
      case 'lm':
        key = `review_data.line_manager_extra_section.keeper_test_section.questions.${num}.value`
        break

      case 'fm':
        key = `review_data.functional_manager_extra_section.keeper_test_section.questions.${num}.value`
        break

      case 'peer':
        key = `review_data.peer_extra_section.keeper_test_section.questions.${num}.value`
        break
    }

    if (!key || !get(errors, key) || get(values, key)) {
      return null
    }

    return <BottomText error="This field may not be empty." />
  }

  return (
    <>
      <Widget p="s-16">
        <VStack space="s-32" ref={ref}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap="s-16" width="30%">
              <Avatar useIcon="HelpChat" />
              <Text variant="primary">Bar raiser</Text>
            </Flex>
          </Flex>
          {data.keeper_test_section.questions.map((question, num) => {
            return (
              <VStack space="s-16" key={num}>
                <Text variant="primary">{question.name}</Text>
                {renderError(num)}
                <Flex justifyContent="left" gap="s-8">
                  {options.map(option => (
                    <FilterButton
                      onClick={() =>
                        !isViewMode && onKeeperChange(String(option.value.id), num)
                      }
                      active={
                        data.keeper_test_section.questions[num].value === option.value.id
                      }
                      style={{ height: '30px' }}
                      key={option.value.id}
                    >
                      {option.value.name}
                    </FilterButton>
                  ))}
                </Flex>
              </VStack>
            )
          })}
        </VStack>
      </Widget>
    </>
  )
})
