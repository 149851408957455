import React, { useState } from 'react'
import { Cell, Icon, Input, Item, VStack } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsTemplateDataFieldInterface,
  DocumentsTemplateMoneyFieldInterface,
  DocumentsTemplateSignatureFieldInterface,
  DocumentsTemplatesInterface,
} from '@src/interfaces/documentsTemplates'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import NewDatePicker from '@components/Inputs/NewDatePicker/NewDatePicker'
import { selectorKeys } from '@src/constants/api'

import {
  FieldKey,
  getSourceIdToLabel,
  getSourceOptions,
  mapKeyToAvatar,
  recipientOptions,
} from './common'

type FieldProps<T> = {
  data: T
}
type FieldSettingsWrapperProps<T> = React.PropsWithChildren<{
  fieldKey: FieldKey
}> &
  FieldProps<T>

const FieldSettingsWrapper = <T extends { placeholder: string }>({
  data,
  fieldKey,
  children,
}: FieldSettingsWrapperProps<T>) => {
  const [showContent, setShowContent] = useState(false)

  return (
    <VStack space="s-4">
      <Item use="button" type="button" onClick={() => setShowContent(!showContent)}>
        <Item.Avatar>{mapKeyToAvatar[fieldKey]}</Item.Avatar>
        <Item.Content>
          <Item.Title>{data.placeholder} settings</Item.Title>
        </Item.Content>
        <Item.Side>
          <Icon name={showContent ? 'ChevronUp' : 'ChevronDown'} />
        </Item.Side>
      </Item>
      {showContent && (
        <VStack p="s-4" pt={0} space="s-4">
          {children}
        </VStack>
      )}
    </VStack>
  )
}

const TextField = ({ data }: FieldProps<DocumentsTemplateDataFieldInterface>) => {
  return (
    <FieldSettingsWrapper<DocumentsTemplateDataFieldInterface>
      fieldKey="text"
      data={data}
    >
      <Input
        label="Field label"
        value={data.placeholder}
        onChange={e => {
          data.placeholder = e.currentTarget.value
        }}
      />
      <RadioSelectInput
        label="Source Type"
        options={getSourceOptions('text')}
        value={data.source_type}
        onChange={newValue => {
          if (newValue) {
            data.source_type = newValue
          }
        }}
        searchable={false}
      />
      {data.source_type.id === 'custom_value' && (
        <Input
          label={getSourceIdToLabel('text').custom_value}
          value={data.custom_value}
          onChange={e => {
            data.custom_value = e.currentTarget.value
          }}
        />
      )}
      {data.source_type.id === 'to_be_filled' && (
        <RadioSelectInput
          label="Recipient"
          options={recipientOptions}
          value={data.recipient}
          onChange={newValue => {
            if (newValue) {
              data.recipient = newValue
            }
          }}
          searchable={false}
        />
      )}
    </FieldSettingsWrapper>
  )
}

const NumberField = ({ data }: FieldProps<DocumentsTemplateDataFieldInterface>) => {
  return (
    <FieldSettingsWrapper<DocumentsTemplateDataFieldInterface>
      fieldKey="number"
      data={data}
    >
      <Input
        label="Field label"
        value={data.placeholder}
        onChange={e => {
          data.placeholder = e.currentTarget.value
        }}
      />
      <RadioSelectInput
        label="Source Type"
        options={getSourceOptions('number')}
        value={data.source_type}
        onChange={newValue => {
          if (newValue) {
            data.source_type = newValue
          }
        }}
        searchable={false}
      />
      {data.source_type.id === 'custom_value' && (
        <Input
          type="number"
          label={getSourceIdToLabel('number').custom_value}
          value={data.custom_value}
          onChange={e => {
            data.custom_value = e.currentTarget.value
          }}
        />
      )}
      {data.source_type.id === 'to_be_filled' && (
        <RadioSelectInput
          label="Recipient"
          options={recipientOptions}
          value={data.recipient}
          onChange={newValue => {
            if (newValue) {
              data.recipient = newValue
            }
          }}
          searchable={false}
        />
      )}
    </FieldSettingsWrapper>
  )
}

const DateField = ({ data }: FieldProps<DocumentsTemplateDataFieldInterface>) => {
  return (
    <FieldSettingsWrapper<DocumentsTemplateDataFieldInterface>
      fieldKey="date"
      data={data}
    >
      <Input
        label="Field label"
        value={data.placeholder}
        onChange={e => {
          data.placeholder = e.currentTarget.value
        }}
      />
      <RadioSelectInput
        label="Source Type"
        options={getSourceOptions('date')}
        value={data.source_type}
        onChange={newValue => {
          if (newValue) {
            data.source_type = newValue
          }
        }}
        searchable={false}
      />
      {data.source_type.id === 'custom_value' && (
        <NewDatePicker
          label={getSourceIdToLabel('date').custom_value}
          value={data.custom_value}
          onChange={newDate => {
            if (newDate) {
              data.custom_value = newDate.toISOString()
            }
          }}
        />
      )}
      {data.source_type.id === 'to_be_filled' && (
        <RadioSelectInput
          label="Recipient"
          options={recipientOptions}
          value={data.recipient}
          onChange={newValue => {
            if (newValue) {
              data.recipient = newValue
            }
          }}
          searchable={false}
        />
      )}
    </FieldSettingsWrapper>
  )
}

const MoneyField = ({ data }: FieldProps<DocumentsTemplateMoneyFieldInterface>) => {
  return (
    <FieldSettingsWrapper<DocumentsTemplateMoneyFieldInterface>
      fieldKey="money"
      data={data}
    >
      <Input
        label="Field label"
        value={data.placeholder}
        onChange={e => {
          data.placeholder = e.currentTarget.value
        }}
      />
      <RadioSelectInput
        label="Source Type"
        options={getSourceOptions('money')}
        value={data.source_type}
        onChange={newValue => {
          if (newValue) {
            data.source_type = newValue
          }
        }}
        searchable={false}
      />
      {data.source_type.id === 'custom_value' && (
        <>
          <Input
            type="money"
            label={getSourceIdToLabel('money').custom_value}
            value={data.custom_value}
            onChange={newValue => {
              if (newValue != null) {
                data.custom_value = newValue
              }
            }}
          />
          <RadioSelectInput
            label="Currency"
            value={data.currency}
            selector={selectorKeys.currencies}
            onChange={newValue => {
              if (newValue) {
                data.currency = newValue
              }
            }}
          />
        </>
      )}
      {data.source_type.id === 'to_be_filled' && (
        <RadioSelectInput
          label="Recipient"
          options={recipientOptions}
          value={data.recipient}
          onChange={newValue => {
            if (newValue) {
              data.recipient = newValue
            }
          }}
          searchable={false}
        />
      )}
    </FieldSettingsWrapper>
  )
}

const SignatureField = ({
  data,
}: FieldProps<DocumentsTemplateSignatureFieldInterface>) => {
  return (
    <FieldSettingsWrapper<DocumentsTemplateSignatureFieldInterface>
      fieldKey="signature"
      data={data}
    >
      <Input
        label="Field label"
        value={data.placeholder}
        onChange={e => {
          data.placeholder = e.currentTarget.value
        }}
      />
      <RadioSelectInput
        label="Recipient"
        options={recipientOptions}
        value={data.recipient}
        onChange={newValue => {
          if (newValue) {
            data.recipient = newValue
          }
        }}
        searchable={false}
      />
    </FieldSettingsWrapper>
  )
}

type Props = {
  pageNum?: number
}
export const FieldsBar = ({ pageNum }: Props) => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  const paginationEnabled = !!pageNum

  return (
    <Cell p="s-4">
      <VStack space="s-8" width="100%" maxHeight="calc(100vh - 100px)" overflow="scroll">
        {values.text_fields
          .filter(field => (paginationEnabled ? field.page_number === pageNum : true))
          .map((field, idx) => (
            <TextField key={`text_field_${idx}`} data={field} />
          ))}
        {values.number_fields
          .filter(field => (paginationEnabled ? field.page_number === pageNum : true))
          .map((field, idx) => (
            <NumberField key={`number_field_${idx}`} data={field} />
          ))}
        {values.date_fields
          .filter(field => (paginationEnabled ? field.page_number === pageNum : true))
          .map((field, idx) => (
            <DateField key={`date_field_${idx}`} data={field} />
          ))}
        {values.money_fields
          .filter(field => (paginationEnabled ? field.page_number === pageNum : true))
          .map((field, idx) => (
            <MoneyField key={`money_field_${idx}`} data={field} />
          ))}
        {values.signature_fields
          .filter(field => (paginationEnabled ? field.page_number === pageNum : true))
          .map((field, idx) => (
            <SignatureField key={`signature_field_${idx}`} data={field} />
          ))}
      </VStack>
    </Cell>
  )
}
