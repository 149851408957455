import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Redirect, Route, Switch } from 'react-router-dom'
import MyHiringPipelines from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/MyHiringPipelines'
import SharedHiringPipelines from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/SharedHiringPipelines'
import { useGetHiringPipelinesStats } from '@src/api/recruitment/hiringPipelines'
import TeamHiringPipelines from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/TeamHiringPipelines'

const HiringPipelines = () => {
  const { data, isLoading } = useGetHiringPipelinesStats()

  const tabs = [
    {
      path: ROUTES.RECRUITMENT.HIRING_PIPELINES.MY,
      to: ROUTES.RECRUITMENT.HIRING_PIPELINES.MY,
      title: 'My pipelines',
      component: MyHiringPipelines,
      quickSummary: data?.own_pipelines,
    },
    {
      path: ROUTES.RECRUITMENT.HIRING_PIPELINES.SHARED,
      to: ROUTES.RECRUITMENT.HIRING_PIPELINES.SHARED,
      title: 'Shared with me',
      component: SharedHiringPipelines,
      quickSummary: data?.shared_with_me_pipelines,
    },
  ]

  if (data?.report_pipelines) {
    tabs.push({
      path: ROUTES.RECRUITMENT.HIRING_PIPELINES.TEAM,
      to: ROUTES.RECRUITMENT.HIRING_PIPELINES.TEAM,
      title: 'Team',
      component: TeamHiringPipelines,
      quickSummary: data?.report_pipelines,
    })
  }

  return (
    <>
      <Flex alignItems="flex-start" mb="s-24">
        <TabBarNavigation isSubtab tabs={tabs} loading={isLoading} />
      </Flex>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component totalCount={tab.quickSummary} />
          </Route>
        ))}
        <Redirect to={tabs[0].path} />
      </Switch>
    </>
  )
}

export default HiringPipelines
