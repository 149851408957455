import React, { Dispatch, ReactNode, Ref, SetStateAction, useState } from 'react'
import {
  Avatar,
  Badge,
  Box,
  FilterButton,
  Flex,
  Group,
  Icon,
  Switch,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  PerformanceRating,
  ReviewScorecardInterface,
  ReviewScorecardViewInterface,
  ReviewSummaryInterface,
  SkillCardInterface,
  SkippedJustificationsInterface,
} from '@src/interfaces/performance'
import { get, isEmpty, set } from 'lodash'
import {
  CardContentTypes,
  cardHasMissingValues,
  getRoundedRating,
  GradeOption,
  PerformanceRatingToGraphNumber,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import styled from 'styled-components'
import { ResolveIconType } from '@revolut/ui-kit/types/dist/components/Icon/utils'
import { relationToString } from '@src/features/Scorecard/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { PerformanceRatingTitle } from '@src/constants/performance'

const CardItem = styled(Flex)`
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${Token.color.blue_5};
  }
`

const StyledBadge = styled(Badge)`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
  z-index: ${props => props.theme.zIndex.main};
  min-width: 13px;
  height: 13px;
  padding: 0;
  border: 2px solid ${Token.color.background};
  box-sizing: content-box;
`

export interface CardField {
  field: string
  fieldToSelect?: string
  title: string
  grades: GradeOption[]
  cardIndex?: number
}

interface CardProps {
  data: ReviewScorecardInterface | ReviewSummaryInterface | ReviewScorecardViewInterface
  type: CardContentTypes
  title: string
  icon: ResolveIconType
  fields: CardField[]
  isViewMode?: boolean
  selectedField?: SelectedFieldInterface
  setSelectedField: Dispatch<SetStateAction<SelectedFieldInterface | undefined>>
  onSelectGrade?: (grade: GradeOption, field: CardField) => void
  allowSkip?: boolean
  onSkip?: (value?: string) => void
  justification?: string | SkippedJustificationsInterface[]
  isGradeSelectedRule?: (field: string, grade: GradeOption) => boolean
  headerRef?: Ref<HTMLDivElement>
  finalRating?: PerformanceRating
  additionalInfo?: ReactNode
}

const ExceedingExpectationBadge = () => {
  const exceedingTooltip = useTooltip()

  return (
    <StyledBadge
      useIcon="16/ExclamationMarkSign"
      backgroundColor={Token.color.orange}
      {...exceedingTooltip.getAnchorProps()}
    >
      <Tooltip {...exceedingTooltip.getTargetProps()} placement="top-end" width={270}>
        This rating is above the expectation. Please add justification for this card.
      </Tooltip>
    </StyledBadge>
  )
}

export const Card = ({
  data,
  type,
  title,
  icon,
  fields,
  isViewMode = false,
  selectedField,
  setSelectedField,
  onSelectGrade,
  allowSkip,
  onSkip,
  justification,
  isGradeSelectedRule,
  headerRef,
  finalRating,
  additionalInfo,
}: CardProps) => {
  const { query } = useQuery()
  const { errors } = useLapeContext<ReviewScorecardInterface>()
  const [skipSection, setSkipSection] = useState(
    Array.isArray(justification)
      ? !!justification[0]?.value
      : justification !== undefined,
  )
  const skipTooltip = useTooltip<HTMLButtonElement>()

  const onCardClick = (field: CardField) => {
    if (selectedField?.type === type && selectedField.cardIndex === field.cardIndex) {
      setSelectedField(undefined)
    } else {
      setSelectedField({
        type,
        field: field.fieldToSelect ? field.fieldToSelect : field.field,
        cardIndex: field.cardIndex,
      })
    }
  }

  const onGradeClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    field: CardField,
    grade: GradeOption,
  ) => {
    if (!isViewMode) {
      e.stopPropagation()
      const option = {
        type,
        field: field.fieldToSelect ? field.fieldToSelect : field.field,
        cardIndex: field.cardIndex,
      }
      setSelectedField(option)
      if (onSelectGrade) {
        onSelectGrade(grade, field)
      } else {
        set(data, field.field, grade.key)
      }
    }
  }

  const handleSkip = (skip: boolean) => {
    setSkipSection(skip)
    onSkip?.(
      skip ? 'I don’t have sufficient knowledge to assess this section.' : undefined,
    )
    if (skip) {
      setSelectedField(undefined)
    } else {
      onCardClick(fields[0])
    }
  }

  const getFieldDetails = (field: CardField, grade: GradeOption) => {
    const fieldValue = get(data, field.field)
    const selected =
      isGradeSelectedRule?.(field.field, grade) ||
      grade.key === getRoundedRating(fieldValue)
    const expectedRating = fieldValue?.rating_expectation as PerformanceRating
    const aboveExpectation =
      selected &&
      PerformanceRatingToGraphNumber[grade.key] >
        PerformanceRatingToGraphNumber[expectedRating]

    return { selected, aboveExpectation }
  }

  const justifications = Array.isArray(justification) ? justification : []
  const singleViewFilter = !!justifications.length && !!query[Queries.ReviewerId]

  return (
    <Group>
      <VStack p="s-4" ref={headerRef}>
        <Flex alignItems="center" justifyContent="space-between" p="s-12">
          <Flex alignItems="center" gap="s-16" width="30%">
            <Avatar useIcon={icon} />
            <Text variant="primary">{title}</Text>
          </Flex>
          {allowSkip && !isViewMode && (
            <Box {...skipTooltip.getAnchorProps()}>
              <Switch
                checked={!skipSection}
                onChange={() => handleSkip(!skipSection)}
                disabled={isViewMode}
              />
              <Tooltip {...skipTooltip.getTargetProps()}>
                {skipSection ? 'Enable section' : 'Switch off to skip section'}
              </Tooltip>
            </Box>
          )}
          {isViewMode && finalRating && (
            <Text
              variant="primary"
              color={
                finalRating === PerformanceRating.skipped
                  ? Token.color.greyTone50
                  : undefined
              }
            >
              {PerformanceRatingTitle[finalRating]}
            </Text>
          )}
        </Flex>
        {isViewMode && !!justifications.length && !singleViewFilter && (
          <VStack space="s-8">
            {justifications.map((value, i) => (
              <Box
                key={i}
                p="s-16"
                mx="s-16"
                mb={justifications.length === 1 ? 's-16' : 0}
                data-testid="skip-section"
                border={`1px solid ${Token.color.greyTone10}`}
                borderRadius={Token.radius.r16}
              >
                <Text variant="primary" use="div" mb="s-4">
                  {value.review.reviewer.display_name} (
                  {relationToString(value.review.reviewer_relation, true)}) skipped this
                  section
                </Text>
                <Text color="grey-tone-50">“{value.value}”</Text>
              </Box>
            ))}
          </VStack>
        )}
        {additionalInfo}
        {(!skipSection || (isViewMode && !singleViewFilter)) && (
          <>
            {fields.map((field, ind) => {
              const fieldValue = get(data, field.field)
              const hasMissingValues = cardHasMissingValues(
                type,
                fieldValue as SkillCardInterface,
                errors,
              )
              const hasErrors = !isEmpty(get(errors, field.field))
              const validationFailed = hasErrors && hasMissingValues
              return (
                <CardItem
                  key={ind}
                  justifyContent="space-between"
                  alignItems="center"
                  my="s-2"
                  p="s-20"
                  bg={
                    validationFailed
                      ? Token.color.inputError
                      : selectedField &&
                        (selectedField.field === field.field ||
                          selectedField.field === field.fieldToSelect)
                      ? Token.color.blue_5
                      : undefined
                  }
                  borderRadius={Token.radius.r12}
                  onClick={() => onCardClick(field)}
                >
                  <Text
                    variant="bullet"
                    fontWeight={500}
                    lineHeight={Token.lineHeight.caption}
                  >
                    {field.title}
                  </Text>
                  <Flex alignItems="center" justifyContent="flex-end">
                    <Flex gap="s-4" alignItems="center">
                      {field.grades.map(grade => {
                        const { selected, aboveExpectation } = getFieldDetails(
                          field,
                          grade,
                        )
                        return (
                          <Flex
                            key={grade.key}
                            justifyContent="center"
                            style={{ position: 'relative' }}
                          >
                            {aboveExpectation && <ExceedingExpectationBadge />}
                            <FilterButton
                              onClick={e => onGradeClick(e, field, grade)}
                              active={selected}
                              style={{ height: '30px' }}
                              px="s-12"
                              disabled={isViewMode && !selected}
                            >
                              {grade.text}
                            </FilterButton>
                          </Flex>
                        )
                      })}
                    </Flex>
                    <Box ml="s-8">
                      <Icon
                        name="ChevronRight"
                        size={20}
                        color={Token.color.greyTone20}
                      />
                    </Box>
                  </Flex>
                </CardItem>
              )
            })}
          </>
        )}
      </VStack>
    </Group>
  )
}
