import React from 'react'
import { APPLICATIONS } from '@src/constants/hub'
import { ROUTES } from '@src/constants/routes'
import MyHiringPipelines from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/MyHiringPipelines'
import SharedHiringPipelines from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/SharedHiringPipelines'
import { useGetHiringPipelinesStats } from '@src/api/recruitment/hiringPipelines'
import TeamHiringPipelines from '@src/pages/Recruitment/RecruitmentSubTabs/HiringPipelines/TeamHiringPipelines'
import HubApp from '@src/features/HubApp/HubApp'

const HiringPipelines = () => {
  const { data, isLoading } = useGetHiringPipelinesStats()

  const tabs = [
    {
      path: ROUTES.APPS.HIRING_PIPELINES.MY,
      url: ROUTES.APPS.HIRING_PIPELINES.MY,
      title: 'My pipelines',
      component: () => <MyHiringPipelines totalCount={data?.own_pipelines} />,
    },
    {
      path: ROUTES.APPS.HIRING_PIPELINES.SHARED,
      url: ROUTES.APPS.HIRING_PIPELINES.SHARED,
      title: 'Shared with me',
      component: () => (
        <SharedHiringPipelines totalCount={data?.shared_with_me_pipelines} />
      ),
    },
  ]

  if (data?.report_pipelines) {
    tabs.push({
      path: ROUTES.APPS.HIRING_PIPELINES.TEAM,
      url: ROUTES.APPS.HIRING_PIPELINES.TEAM,
      title: 'Team',
      component: () => <TeamHiringPipelines totalCount={data?.report_pipelines} />,
    })
  }

  return <HubApp app={{ ...APPLICATIONS.hiringPipelines, tabs }} loading={isLoading} />
}

export default HiringPipelines
